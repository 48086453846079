import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoading = _resolveComponent("AppLoading")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_AppLoading, {
        key: 0,
        offline: _ctx.isOffline
      }, null, 8, ["offline"]))
    : (_openBlock(), _createBlock(_component_ion_app, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet)
        ]),
        _: 1
      }))
}